@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import url('fontawesome-all.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,700,900');

/*
	Escape Velocity by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

	@include breakpoints((
		xlarge:  ( 1281px,  1680px ),
		large:   ( 981px,   1280px ),
		medium:  ( 737px,   980px  ),
		small:   ( null,    736px  )
	));

// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)

	html, body, div, span, applet, object,
	iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
	pre, a, abbr, acronym, address, big, cite,
	code, del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var, b,
	u, i, center, dl, dt, dd, ol, ul, li, fieldset,
	form, label, legend, table, caption, tbody,
	tfoot, thead, tr, th, td, article, aside,
	canvas, details, embed, figure, figcaption,
	footer, header, hgroup, menu, nav, output, ruby,
	section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}

	html {scroll-behavior: smooth;}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style:none;
	}

	blockquote,	q {
		quotes: none;

		&:before,
		&:after {
			content: '';
			content: none;
		}
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

	mark {
		background-color: transparent;
		color: inherit;
	}

	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	input, select, textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	// Set box model to border-box.
	// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
		html {
			box-sizing: border-box;
		}

		*, *:before, *:after {
			box-sizing: inherit;
		}

	body {

		// Stops initial animations until page loads.
			&.is-preload {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

	}

	body, input, textarea, select {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 400;
		color: #919499;
		font-size: 14pt;
		line-height: 1.75em;
		letter-spacing: 0.025em;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 700;
		color: #484d55;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	a {
		@include vendor('transition', 'color .25s ease-in-out');
		text-decoration: underline;
		color: #717479;

		&:hover {
			text-decoration: none;
		}
	}

	strong, b {
		font-weight: 700;
		color: #484d55;
	}

	blockquote {
		border-left: solid 0.5em #ddd;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	em, i {
		font-style: italic;
	}

	hr {
		border: 0;
		border-top: solid 1px #ddd;
		margin: 2em 0 2em 0;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	.nobr {
		white-space: nowrap;
	}

	br.clear {
		clear: both;
	}

	p, ul, ol, dl, table, blockquote, form {
		margin-bottom: 2em;
	}

/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 68em;

		@include breakpoint('<=xlarge') {
			width: 70em;
		}

		@include breakpoint('<=large') {
			width: calc(100% - 100px);
		}

		@include breakpoint('<=medium') {
			width: calc(100% - 100px);
		}

		@include breakpoint('<=small') {
			width: calc(100% - 40px);
		}
	}

/* Row */

	.row {
		@include html-grid((50px, 50px));

		@include breakpoint('<=xlarge') {
			@include html-grid((50px, 50px), 'xlarge');
		}

		@include breakpoint('<=large') {
			@include html-grid((35px, 35px), 'large');
		}

		@include breakpoint('<=medium') {
			@include html-grid((50px, 50px), 'medium');
		}

		@include breakpoint('<=small') {
			@include html-grid((30px, 30px), 'small');
		}
	}

/* Section/Article */

	section,
	article {
		margin: 0 0 4em 0;
	}

	section > :last-child,
	article > :last-child,
	section:last-child,
	article:last-child {
		margin-bottom: 0;
	}

	header {
		&.style1 {
			text-align: center;
			padding: 3em 0 3em 0;

			h2 {
				font-weight: 700;
				font-size: 1.75em;
				letter-spacing: 0.075em;
				line-height: 1.5em;
			}

			p {
				color: #b1b4b9;
				display: block;
				margin: 1.15em 0 0 0;
				font-size: 1.3em;
				letter-spacing: 0.075em;
				line-height: 1.5em;
			}
		}
	}

/* Table */

	table {
		width: 100%;

		&.default {
			width: 100%;

			tbody {
				tr:nth-child(2n+2) {
					background: #f4f4f4;
				}
			}

			td {
				padding: 0.5em 1em 0.5em 1em;
			}

			th {
				text-align: left;
				font-weight: 400;
				padding: 0.5em 1em 0.5em 1em;
			}

			thead {
				background: #484d55;
				color: #fff;
			}

			tfoot {
				background: #eee;
			}
		}
	}

/* Form */

	form {
		label {
			display: block;
			font-weight: 700;
			color: #484d55;
			margin: 0.25em 0 0.5em 0;
		}

		input[type="text"],
		input[type="email"],
		input[type="password"],
		select,
		textarea {
			@include vendor('transition', 'all .25s ease-in-out');
			-webkit-appearance: none;
			display: block;
			border: 0;
			background: #eee;
			box-shadow: inset 0px 0px 1px 0px #a0a1a7;
			border-radius: 0.35em;
			width: 100%;
			padding: 0.75em 1em 0.75em 1em;

			&:focus {
				background: #f8f8f8;
			}
		}

		input[type="text"],
		input[type="email"]
		input[type="password"] {
			line-height: 1em;
		}

		select {
			line-height: 1em;
		}

		textarea {
			min-height: 8em;
		}

		ul.actions {
			margin-top: 0;
		}

		::-webkit-input-placeholder {
			color: #555 !important;
			font-style: italic;
			line-height: 1.35em;
		}

		:-moz-placeholder {
			color: #555 !important;
			font-style: italic;
		}

		::-moz-placeholder {
			color: #555 !important;
			font-style: italic;
		}

		:-ms-input-placeholder {
			color: #555 !important;
			font-style: italic;
		}

		::-moz-focus-inner {
			border: 0;
		}
	}

/* Image */

	.image {
		display: inline-block;

		img {
			display: block;
			width: 100%;
		}

		&.fit {
			display: block;
			width: 100%;
		}

		&.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		&.centered {
			display: block;
			margin: 0 0 2em 0;

			img {
				margin: 0 auto;
				width: auto;
			}
		}

		&.featured {
			display: block;
			width: 100%;
			margin: 0 0 2em 0;
		}
	}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		@include vendor('transition', 'all .25s ease-in-out');
		display: inline-block;
		background: #444;
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.25em;
		text-decoration: none;
		border-radius: 0.35em;
		border: 0;
		outline: 0;
		cursor: pointer;
		padding: 0 2.25em 0 2.25em;
		font-size: 0.9em;
		min-width: 12em;
		height: 4em;
		line-height: 4em;

		&:hover {
			background-color: #558C93;
		}

		&.large {
			font-size: 1em;
			min-width: 14em;
			white-space: nowrap;
			padding: 0 5px;
		}

		&.style1 {
			background: #77A0A9 url('images/overlay.png');
			color: #fff;

			&:hover {
				background-color: #558C93;
			}

			&:active {
				background-color: #558C93;
			}
		}

		&.style2 {
			background: none;
			color: #606167;
			box-shadow: inset 0px 0px 2px 0px #a0a1a7;

			&:hover {
				box-shadow: inset 0px 0px 2px 0px #606167;
			}

			&:active {
				box-shadow: inset 0px 0px 2px 0px #202127;
			}
		}

		&.style3 {
			background: #2f333b url('images/overlay.png');
			color: #fff;

			&:hover {
				background-color: #3f434b;
			}

			&:active {
				background-color: #1f232b;
			}
		}
	}

/* List */

	ul {
		list-style: disc;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.style1 {
		}

		&.style2 {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px #eee;
				padding: 1.5em 0 0 0;
				margin: 1.5em 0 0 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
					margin-top: 0;
				}
			}
		}

		&.style3 {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px #eee;
				padding: 0.5em 0 0 0;
				margin: 0.5em 0 0 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
					margin-top: 0;
				}
			}
		}
	}

	ol {
		list-style: decimal;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

/* Actions */

	ul.actions {
		list-style: none;
		padding-left: 0;
		margin: 3em 0 0 0;

		li {
			display: inline-block;
			margin: 0 0.75em 0 0.75em;
			padding-left: 0;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&.special {
			text-align: center;
		}
	}

/* Feature List */

	.feature-list {
		max-width: 58em;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		top: 0.75em;

		section {
			border-top: solid 1px #eee;
			padding-top: 3em;
			padding-left: 5em;
			position: relative;
		}

		@include breakpoint('>medium') {
			> .row > :nth-child(-n + 2) > section {
				border-top: 0;
				padding-top: 0;
			}
		}

		h3 {
			color: #5E7C82 !important;
			margin: -0.35em 0 0.75em 0;
			font-size: 1.15em;
			letter-spacing: 0.05em;

			&:before {
				display: block;
				color: #fff;
				background: #2f333b url('images/overlay.png');
				border-radius: 2.5em;
				text-align: center;
				width: 2.5em;
				height: 2.5em;
				line-height: 2.5em;
				margin-right: 0.75em;
				position: absolute;
				left: 0;
				margin-top: -0.5em;
			}
		}

		&.small {
			section {
				padding-left: 3.5em;
			}

			h3 {
				&:before {
					font-size: 0.8em;
				}
			}
		}
	}

/* Icons */

	.icon {
		@include icon;
		text-decoration: none;

		&:before {
			line-height: inherit;
			font-size: 1.25em;
		}

		> .label {
			display: none;
		}

		&.solid {
			&:before {
				font-weight: 900;
			}
		}

		&.brands {
			&:before {
				font-family: 'Font Awesome 5 Brands';
			}
		}
	}

/* Box */

	.box {
		header {
			margin: 0 0 1.5em 0;

			&.style1 {
				position: relative;
				margin: -0.5em 0 0 0;
				padding-top: 0;
			}
		}

		h2 {
			margin: 0 0 0.75em 0;
			font-size: 1.15em;
			letter-spacing: 0.05em;
		}

		h3 {
			margin: 0 0 0.5em 0;
			font-size: 1em;
			font-weight: 600;
			letter-spacing: 0.05em;
		}

		&.post-excerpt {
			.image.left {
				position: relative;
				top: 0.5em;
				width: 5em;
			}

			h3, p {
				margin-left: 7em;
			}
		}
	}

/* Wrapper */

	.wrapper {
		position: relative;
		padding: 6em 0 9em 0;

		.title {
			position: absolute;
			top: 0;
			left: 50%;
			text-align: center;
			text-transform: uppercase;
			display: block;
			font-weight: 700;
			letter-spacing: 0.25em;
			font-size: 0.9em;
			width: 25em;
			height: 3.25em;
			top: -3.25em;
			line-height: 3.25em;
			margin-bottom: -3.25em;
			margin-left: -12.5em;
			padding-top: 0;

			&:before {
				content: '';
				position: absolute;
				bottom: -38px;
				left: -35px;
				width: 35px;
				height: 38px;
				background: url('images/shadow.png');
			}

			&:after {
				@include vendor('transform', 'scaleX(-1)');
				content: '';
				position: absolute;
				bottom: -38px;
				right: -35px;
				width: 35px;
				height: 38px;
				background: url('images/shadow.png');
			}
		}

		&.style1 {
			background: #77A0A9 url('images/overlay.png');
			color: #eee;
			color: rgba(255, 255, 255, 0.75);

			.title {
				background: #77A0A9 url('images/overlay.png');
				color: #fff;
			}

			h1, h2, h3, h4, h5, h6, strong, b, a {
				color: #fff;
			}
		}

		&.style2 {
			background: #fff;

			.title {
				background: #fff;
				color: #484d55;
			}
		}

		&.style3 {
			background: #f3f3f3 url('images/overlay.png');

			.title {
				background: #f3f3f3 url('images/overlay.png');
				color: #484d55;
			}

			.image {
				border: solid 3px #fff;
			}
		}
	}

/* Page Wrapper */

	#page-wrapper {
		> section {
			margin-bottom: 0;
		}
	}

/* Header */

	#header {
		@include vendor('display', 'flex');
		@include vendor('align-items', 'center');
		@include vendor('justify-content', 'center');
		position: relative;
		height: 24em;
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-home.jpg') center center;
		background-size: cover;
		padding: 0;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('images/overlay.png');
		}

		.homepage & {
			height: 35em;
		}
	}
	.home{
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-home.jpg') center center!important;
		background-size: cover!important;
	}
	.aboutus {
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-about-us.jpg') center center!important;
		background-size: cover!important;
	}
	.inspiration {
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-inspiration.jpg') center center!important;
		background-size: cover!important;
	}
	.contactus {
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-contactus.jpg') center center!important;
		background-size: cover!important;
	}
	.products {
		background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-products.jpg') center center!important;
		background-size: cover!important;
	}

	.contact-main .feature-list > .row > :first-child > section, .contact-main .feature-list > .row > :nth-child(-n+2) > section {
		border-top: solid 1px #eee;
		padding-top: 4em;
	}

	.contact-main .feature-list h3:before {
		color: #5E7C82;
		background-color: transparent;
	}

	.contact-main .feature-list ul {
		padding-left: 0;
	}

	.about-main {
		.text-start p {
			line-height: 1.6;
			margin-bottom: 1em;
			padding: 15px;
			color: #333;
			font-weight: 400;
			letter-spacing: 0.5px;
			border-radius: 5px;
			text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
		  }
	}

	/* SCSS for modal */
	.fullscreen-modal {
		--bs-modal-width: 90vw; // Ensure the modal can take up to 90% of the viewport width
		justify-content: center; // Center content horizontally
		align-items: center; // Center content vertically
		
		.modal-dialog {
			max-width: 90vw; // Restrict modal width to 90% of the viewport width
			margin: 0 auto; // Center the modal horizontally
		  }
		
		  .modal-content {
			background-color: transparent; // Remove background for better display
			border: none; // Remove border
			display: flex; // Use flexbox to allow content alignment
			justify-content: center; // Center content horizontally
			align-items: center; // Center content vertically
			max-width: 90vw; // Prevent content from exceeding viewport width
			width:auto;
		  }
		
		  .modal-body {
			display: inline-flex; // Inline-flex allows content to shrink-wrap the image
			padding: 0; // Remove padding
			justify-content: center;
			align-items: center;
			width: auto; // Let width dynamically adjust to the image
			height: auto; // Let height dynamically adjust to the image
		  }
		
		  img {
			max-width: 90vw; // Image scales down to fit within 90% of the viewport width
			max-height: 90vh; // Image scales down to fit within 90% of the viewport height
			width: auto; // Maintain natural width
			height: auto; // Maintain natural height
			object-fit: contain; // Ensure the aspect ratio is preserved
		  }
	  }

	/* SCSS for Breadcrumb Styling */

// Variables for easy customization
$breadcrumb-font-size: 1em;
$breadcrumb-icon-size: 0.85em;
$breadcrumb-color: #666;
$breadcrumb-separator-color: #999;
$breadcrumb-padding: 3px; // Reduced padding to bring items closer
$breadcrumb-margin-top: -15px; // Pull breadcrumb closer to h1

.page-title {
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px; // Reduced margin to bring breadcrumb closer

  @include breakpoint('<=small') {
    font-size: 1.2em; // Smaller font size for mobile
    margin-bottom: 3px; // Slightly reduce space to bring closer to breadcrumb
  }
}

.breadcrumb-container {
  display: flex;
  justify-content: center;
  margin-top: $breadcrumb-margin-top;

  @include breakpoint('<=small') {
    margin-top: -15px; // Fine-tune spacing for mobile
  }
}

.slim-breadcrumb {
  font-size: $breadcrumb-font-size;
  padding: 0;
  background-color: transparent;

  .breadcrumb-item {
    padding: 0 $breadcrumb-padding;

    a {
      color: $breadcrumb-color;
      font-weight: 500;
      text-decoration: none;
    }

    i {
      font-size: $breadcrumb-icon-size;
      margin-right: 3px;
    }

    & + .breadcrumb-item::before {
      content: "/";
      padding: 0 $breadcrumb-padding;
      color: $breadcrumb-separator-color;
      font-weight: normal;
    }
  }
}

/* Logo */

	#logo {
		width: 100%;
		text-align: center;
		position: relative;
		top: 1.5em;

		.site-name {
			font-weight: 900;
			text-transform: uppercase;
			color: #fff;
			font-size: 2em;
			letter-spacing: 0.25em;

			a {
				text-decoration: none;
				color:#fff;
			}
		}

		p {
			color: #eee;
			color: rgba(255, 255, 255, 0.5);
			text-transform: uppercase;
			margin: 1.25em 0 0 0;
			display: block;
			letter-spacing: 0.2em;
			font-size: 0.9em;
		}
	}

/* Nav */

	#nav {
		position: absolute;
		display: block;
		top: 2.5em;
		left: 0;
		width: 100%;
		text-align: center;

		> ul {
			display: inline-block;
			border-radius: 0.35em;
			box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
			padding: 0 1.5em 0 1.5em;

			> li {
				display: inline-block;
				text-align: center;
				padding: 0 1.5em 0 1.5em;

				> ul {
					display: none;
				}

				> a, > span {
					display: block;
					color: #eee;
					color: rgba(255, 255, 255, 0.75);
					text-transform: uppercase;
					text-decoration: none;
					font-size: 0.7em;
					letter-spacing: 0.25em;
					height: 5em;
					line-height: 5em;
					-moz-transition: all .25s ease-in-out;
					-webkit-transition: all .25s ease-in-out;
					-o-transition: all .25s ease-in-out;
					-ms-transition: all .25s ease-in-out;
					transition: all .25s ease-in-out;
					outline: 0;
				}

				&:hover {
					> a {
						color: #fff;
					}
				}

				&.active {
					> a, > span {
						color: #fff;
					}
				}
			}
		}
	}

	.dropotron {
		background: #222835 url('images/overlay.png');
		background-color: rgba(44, 50, 63, 0.925);
		padding: 1.25em 1em 1.25em 1em;
		border-radius: 0.35em;
		box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
		min-width: 12em;
		text-align: left;
		margin-top: -1.25em;
		margin-left: -1px;
		list-style: none;

		&.level-0 {
			margin-top: -1px;
			margin-left: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		a, span {
			@include vendor('transition', 'all .25s ease-in-out');
			display: block;
			color: #eee;
			color: rgba(255, 255, 255, 0.75);
			text-transform: uppercase;
			text-decoration: none;
			font-size: 0.7em;
			letter-spacing: 0.25em;
			border-top: solid 1px rgba(255, 255, 255, 0.15);
			line-height: 3em;
		}

		li {
			padding-left: 0;

			&:first-child {
				a, span {
					border-top: 0;
				}
			}

			&:hover {
				> a, > span {
					color: #fff;
				}
			}
		}
	}

/* Intro */

	#intro {
		padding-bottom: 8em;
		text-align: center;
		margin-bottom: 0;

		p.style1 {
			font-size: 1.5em;
			letter-spacing: 0.075em;
		}

		p.style2 {
			font-weight: 700;
			color: #fff;
			border-radius: 0.35em;
			box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
			font-size: 2.75em;
			letter-spacing: 0.075em;
			line-height: 1.35em;
			padding: 1em 0 1em 0;
			margin-bottom: 1em;

			a {
				color: inherit;
				text-decoration: none;
			}
		}

		p.style3 {
			font-size: 1.1em;
			width: 48em;
			margin: 0 auto;
		}
	}

/* Features */

	#features {
		header {
			&.style1 {
				padding-bottom: 5em;
			}
		}

		ul.actions {
			margin-top: 5em;
		}
	}

/* Highlights */

	#highlights {
		.highlight {
			text-align: center;

			h3 {
				color: #5E7C82;
				margin: 0 0 0.75em 0;
				font-size: 1.15em;
				letter-spacing: 0.05em;
			}
		}
	}

/* Profile */
.team4, .feature {
	color: #6a6f7a;
  font-weight: 300;

  p {
	text-align: left;
	line-height: 1.6;
	padding: 5px;
	font-weight: 400;
	letter-spacing: 0.5px;
	border-radius: 5px;
	text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
  }

  h1, h2, h3, h4, h5, h6 {
	color: #3e4555;
  }

  .font-weight-medium {
	font-weight: 500;
	}

	h5 {
		line-height: 22px;
		font-size: 18px;
	}

	.subtitle {
		color: #8d97ad;
		line-height: 24px;
	}

	.button {letter-spacing: 0;}
}




/* Feature */
.feature .feature-item:hover {
    border: 1px solid #558C93;
}

.feature .feature-item {
    text-align: center;
    border-radius: 10px;
    background: var(--bs-light);
    border: 1px solid transparent;
    transition: 0.5s;
}

.text-primary {
    color: #2F4F4F !important;
}

/* Main */

	#main {
		margin-bottom: 50px;
	}

/* Footer */

	#footer {
		background: #282b34 url('images/overlay.png');
		color: #eee;
		color: rgba(255, 255, 255, 0.5);
		padding-bottom: 6em;

		h1, h2, h3, h4, h5, h6, strong, b, a {
			color: #fff;
		}

		hr {
			border-top-color: #333;
			border-top-color: rgba(255, 255, 255, 0.05);
		}

		form {
			input[type="text"],
			input[type="email"],
			input[type="password"],
			select,
			textarea {
				background: #ccc;
				box-shadow: none;

				&:focus {
					background: #fff;
				}
			}
		}

		input[type="button"],
		input[type="submit"],
		input[type="reset"],
		button,
		.button {
			color: #fff;
			box-shadow: inset 0px 0px 2px 0px rgba(255, 255, 255, 0.5);

			&:hover {
				color: #fff;
				box-shadow: inset 0px 0px 2px 0px rgba(255, 255, 255, 0.65);
			}

			&:active {
				box-shadow: inset 0px 0px 2px 0px rgba(255, 255, 255, 0.75);
			}

			&.style2 {
				&:active {
					box-shadow: inset 0px 0px 2px 0px rgba(255, 255, 255, 0.75);
				}
			}
		}

		.title {
			background: #282b34 url('images/overlay.png');
			color: #eee;
		}

		header {
			&.style1 {
				padding-bottom: 6em;
				margin-bottom: 6em;
				border-bottom: solid 1px rgba(255, 255, 255, 0.05);

				h2 {
					color: #fff;
				}

				.p {
					color: inherit;
				}
			}
		}

		.feature-list {
			max-width: 100%;

			section {
				border-top-color: rgba(255, 255, 255, 0.05);
			}

			h3 {
				&:before {
					background: #3d4249 url('images/overlay.png');
				}
			}
		}
	}

/* Copyright */

	#copyright {
		text-align: center;
		padding-top: 6em;
		margin-top: 6em;
		border-top: solid 1px rgba(255, 255, 255, 0.05);

		ul {
			display: inline-block;
			border-radius: 0.35em;
			box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.05);
			color: #aaa;
			color: rgba(255, 255, 255, 0.25);
			padding: 0.75em 2em;
			font-size: 0.9em;

			li {
				display: inline-block;
				margin-left: 1em;
				padding-left: 1em;
				border-left: solid 1px #333;
				border-left-color: rgba(255, 255, 255, 0.05);

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		a {
			color: inherit;

			&:hover {
				color: #fff;
			}
		}
	}

/* XLarge */

	@include breakpoint('<=xlarge') {

		/* Basic */
			body, input, textarea, select {
				font-size: 12pt;
			}

	}

/* Large */

	@include breakpoint('<=large') {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
				line-height: 1.5em;
				letter-spacing: 0.015em;
			}

		/* Wrapper */

			.wrapper {
				padding: 4em 0 7em 0;
			}

		/* Logo */

			#logo {
				margin-top: -0.5em !important;

				.site-name {
					font-size: 2em;
				}
			}

		/* Intro */

			#intro {
				padding-bottom: 7em;

				p.style1 {
					font-size: 1.25em;
				}

				p.style2 {
					font-size: 2.25em;
				}
			}

		/* Footer */

			#footer {
				padding-bottom: 4em;

				header {
					&.style1 {
						padding-bottom: 4em;
						margin-bottom: 4em;
						border-bottom: solid 1px rgba(255, 255, 255, 0.05);
					}
				}
			}

		/* Copyright */

			#copyright {
				padding-top: 4em;
				margin-top: 4em;
			}

	}

/* Medium */

	#navPanel, #titleBar {
		display: none;
	}

	@include breakpoint('<=medium') {

		/* Section/Article */

			header {
				br {
					display: none;
				}
			}

		/* Form */

			form {
				ul.actions {
					text-align: center;
				}
			}

		/* Feature List */

			.feature-list {
				max-width: 40em;

				section {
					padding-top: 4em;
				}

				> .row > :first-child > section {
					border-top: 0;
					padding-top: 0;
				}
			}

		/* Nav */

			#nav {
				display: none;
			}

		/* Logo */

			#logo {
				top: 0;
			}

		/* Intro */

			#intro {
				p.style3 {
					width: auto;
				}
			}

		/* Highlights */

			#highlights {
				.highlight {
					max-width: 40em;
					margin-left: auto;
					margin-right: auto;
				}
			}

		/* Footer */

			#footer {
				.feature-list {
					border-top: solid 1px rgba(255, 255, 255, 0.05);
					padding-top: 4em;

					@include breakpoint('>small') {
						> .row > :nth-child(2) > section {
							border-top: 0;
							padding-top: 0;
						}
					}
				}
			}

		/* Nav */

			#page-wrapper {
				@include vendor('backface-visibility', 'hidden');
				@include vendor('transition', 'transform #{_duration(navPanel)} ease');
				padding-bottom: 1px;
			}

			#titleBar {
				@include vendor('backface-visibility', 'hidden');
				@include vendor('transition', 'transform #{_duration(navPanel)} ease');
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: _misc(z-index-base) + 1;
				background: none;

				.title {
					display: none;
				}

				.toggle {
					@include icon(false, solid);
					position: absolute;
					top: 0;
					left: 0;
					width: 60px;
					height: 44px;
					cursor: pointer;

					&:before {
						@include vendor('transition', 'all .15s ease-in-out');
					
						text-decoration: none;
						content: '\f0c9';
						font-size: 14px;
						position: absolute;
						top: 6px;
						left: 6px;
						display: block;
						width: 54px;
						height: 38px;
						line-height: 38px;
						text-align: center;
						color: rgba(255, 255, 255, 0.75);
						background-color: rgba(92, 95, 103, 0.5);
						border-radius: 0.25em;
					}
					&:active {
						&:before {
							background-color: rgba(92, 95, 103, 0.75);
						}
					}
				}
			}

			#navPanel {
				@include vendor('backface-visibility', 'hidden');
				@include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
				@include vendor('transition', ('transform #{_duration(navPanel)} ease'));
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: _size(navPanel);
				z-index: _misc(z-index-base) + 2;
				background: #242730 url('images/overlay.png');
				box-shadow: inset -3px 0px 4px 0px rgba(0, 0, 0, 0.1);

				.link {
					display: block;
					color: rgba(255, 255, 255, 0.5);
					text-transform: uppercase;
					text-decoration: none;
					font-size: 0.85em;
					letter-spacing: 0.15em;
					text-decoration: none;
					height: 44px;
					line-height: 44px;
					border-top: solid 1px rgba(255, 255, 255, 0.05);
					margin: 0 15px 0 15px;

					&:first-child {
						border-top: 0;
					}
				}

				.indent-1 {
					display: inline-block;
					width: 1em;
				}

				.indent-2 {
					display: inline-block;
					width: 2em;
				}

				.indent-3 {
					display: inline-block;
					width: 3em;
				}

				.indent-4 {
					display: inline-block;
					width: 4em;
				}

				.indent-5 {
					display: inline-block;
					width: 5em;
				}

				.depth-0 {
					color: #fff;
				}
			}

			body {
				&.navPanel-visible {
					

					#titleBar {
						@include vendor('transform', 'translateX(#{_size(navPanel)})');
					}

					#navPanel {
						@include vendor('transform', 'translateX(0)');
					}
				}
			}

	}

/* Small */

	@include breakpoint('<=small') {

		/* Basic */

			body, input, select, textarea {
				line-height: 1.5em;
				font-size: 10.5pt;
				letter-spacing: 0;
			}

			h2, h3, h4, h5, h6 {
				font-size: 1.2em;
				letter-spacing: 0.05em;
				margin: 0 0 1em 0;
			}

			hr {
				margin: 1em 0 1em 0;
			}

		/* Section/Article */

			section, article {
				clear: both;
			}

			header {
				br {
					display: none;
				}

				&.style1 {
					padding: 0 0 1em 0;

					h2 {
						font-size: 1.2em;
						letter-spacing: 0.075em;
						line-height: 1.25em;
					}

					p {
						font-size: 1em;
						letter-spacing: 0;
						display: block;
						margin: 0;
					}
				}
			}

			.home{
				background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-home-sm.jpg') center center!important;
				background-size: cover!important;
			}
			.aboutus {
				background: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)),  url('../../images/banner-aboutus-sm.jpg') center center!important;
				background-size: cover!important;
			}

			.product-type {
				ul {
					display: flex;
					flex-wrap: wrap; /* Allows items to wrap into the next row */
					padding: 0; /* Optional: Remove default padding */
				  }
				  
				  ul li {
					flex: 0 0 50%; /* Each list item takes up 50% of the width */
					box-sizing: border-box; /* Ensures padding/margins are accounted for */
					padding: 5px; /* Optional: Add spacing */
				  }
			}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				display: block;
				width: 100%;
				font-size: 1em;
				max-width: 30em;
				margin: 0 auto;
			}

		/* Actions */

			ul.actions {
				margin: 2em 0 0 0;

				li {
					margin: 15px 0 0 0;
					display: block;

					&:first-child {
						margin-top: 0;
					}
				}
			}

		/* Feature List */

			.feature-list {
				section {
					padding-top: 2em;
					padding-left: 4em;
				}

				h3 {
					&:before {
						font-size: 0.9em;
					}
				}
			}

		/* Box */

			.box {
				&.post-excerpt {
					.image {
						&.left {
							position: relative;
							top: 0.25em;
							width: 25%;
							margin: 0;
						}
					}

					h3, p {
						margin-left: 32.5%;
					}
				}
			}

		/* Wrapper */

			.wrapper {
				padding: 3em 0 6em 0;

				.title {
					font-size: 0.9em;
					width: 18em;
					height: 3em;
					top: -2.9em;
					line-height: 2.5em;
					margin-bottom: -2.5em;
					margin-left: -9em;
					padding-top: 0;

					&:before,
					&:after {
						height: 15px;
						bottom: -15px;
						background-size: 100% 100%;
					}
				}
			}

		/* Header */

			#header {
				height: 17em;

				.homepage & {
					height: 20em;
				}
			}

		/* Logo */

			#logo {
				text-align: center;
				padding-left: 2em;
				padding-right: 2em;

				p {
					margin: 1.25em 0 0 0;
					display: block;
					letter-spacing: 0.2em;
					font-size: 0.9em;
				}

				.site-name, .site-name a {
					font-size: 1.3em;
					letter-spacing: 0.2em;
					color: inherit;
    				text-decoration: none;
					transition: color .25s ease-in-out;
					text-transform: uppercase;
					color: #fff;
				}
			}

		/* Intro */

			#intro {
				text-align: center;
				padding-right: 2em;
				padding-left: 2em;
				padding-bottom: 5em;
				margin: 0 auto;

				p.style1 {
					margin-bottom: 1.5em;
				}

				p.style2 {
					font-size: 1.5em;
					letter-spacing: 0.05em;
					line-height: 1.25em;
					padding: 1.25em;
				}
			}

		/* Features */

			#features {
				header {
					&.style1 {
						padding-bottom: 4em;
					}
				}

				ul.actions {
					margin-top: 4em;
				}
			}

		/* Content */

			#content {
				padding: 0 0 2em 0;

				header {
					&.style1 {
						padding-bottom: 2.5em;
					}
				}
			}

		/* Footer */

			#footer {
				padding-bottom: 0;

				header {
					&.style1 {
						padding-bottom: 0;
						margin-bottom: 2em;
						border-bottom: 0;
					}
				}

				.feature-list {
					border-top: 0;
					padding-top: 0;
				}

				.title {
					width: 20em;
					margin-left: -10em;
					top: -2.9em;
				}
			}

		/* Copyright */

			#copyright {
				padding-top: 0;
				margin-top: 4em;
				border-top: 0;

				ul {
					padding: 1em 2em;
					width: 100%;

					li {
						display: block;
						margin: 0.5em 0 0 0;
						border-left: 0;
						padding: 0;

						&:first-child {
							margin-top: 0;
						}
					}
				}
			}

	}